import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { ApiReloadStatus } from '../../shared/includes/page-header/page-header.model';
import { CompanyItem, CompanyResponseTime } from '../company/company.model';
import { CompanyService } from '../company/company.service';
import { Department, DepartmentRequest, DepartmentResponse, DepartmentResponseTime } from './department.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);
  private companyService = inject(CompanyService);
  private departmentData: DepartmentResponse = {};

  getDepartmentInformationList = (param: DepartmentRequest): Observable<DepartmentResponseTime> => {
    const {
      companyId,
    } = param;

    if (!companyId) {
      return this.pageService.errorHelper('getDepartmentInformationList: missing the required params.');
    }

    const existingValue = get(this.departmentData, `[${companyId}]`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'department');
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const departmentUrl = API_SERVICE_URL + `/company/${companyId}/departmentinformation`;
    return this.http.get<Department[]>(departmentUrl)
      .pipe(
        map((response: Department[]) => {
          const revisedResponse: DepartmentResponseTime = {
            requestAsOf: new Date(),
            departments: response
          };
          this.departmentData = {
            ...this.departmentData,
            [companyId]: revisedResponse
          };
          this.updateReloadStatus({ department: false });
          return revisedResponse;
        })
      );
  }

  getDepartmentInformation = (param: DepartmentRequest): Observable<Department> => {
    const {
      companyId,
      id,
    } = param;

    if (!companyId || !id) {
      return this.pageService.errorHelper('getDepartmentInformation: missing the required params.');
    }

    const existingValue: DepartmentResponseTime = get(this.departmentData, `[${companyId}]`);
    if (!isEmpty(existingValue)) {
      return of(existingValue.departments.find((department: Department) => department.id === id)!);
    }

    const departmentUrl = API_SERVICE_URL + `/company/${companyId}/departmentinformation/${id}`;
    return this.http.get<Department>(departmentUrl);
  }

  postDepartmentInformation = (postData: DepartmentRequest) => {
    const {
      companyId,
    } = postData;

    if (!companyId) {
      return this.pageService.errorHelper('postDepartmentInformation: missing the required params.');
    }

    const departmentAddUrl = API_SERVICE_URL + `/company/${companyId}/departmentinformation`;
    return this.http.post<Department>(departmentAddUrl, postData);
  }

  editDepartmentInformation = (postData: DepartmentRequest) => {
    const {
      companyId,
      id,
    } = postData;

    if (!companyId || !id) {
      return this.pageService.errorHelper('editDepartmentInformation: missing the required params.');
    }

    const departmentEditUrl = API_SERVICE_URL + `/company/${companyId}/departmentinformation/${id}`;
    return this.http.post<Department>(departmentEditUrl, postData);
  }

  deleteDepartmentInformation = (param: Department) => {
    const {
      companyId,
      id,
    } = param;

    if (!companyId || !id) {
      return this.pageService.errorHelper('deleteDepartmentInformation: missing the required params.');
    }

    const departmentDeleteUrl = API_SERVICE_URL + `/company/${companyId}/departmentinformation/${id}`;
    return this.http.delete<Department>(departmentDeleteUrl);
  }

  getCompanyList = (): Observable<CompanyResponseTime> => this.companyService.getCompanyInformation();

  getCompanyDetail = (companyId: number): Observable<CompanyItem> => this.companyService.getCompanyDetail(companyId);

  updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param);

  resetCache() {
    this.departmentData = {};
  }
}
