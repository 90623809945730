import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { JobEmployeeView, JobLevel, JobSummaryByApprover } from 'src/app/client/shared/job-summary/job-summary.model';
import { ApiReloadStatus, GLogger } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { CompanyItem, CompanyResponseTime } from '../company/company.model';
import { CompanyService } from '../company/company.service';
import { EmployeeRequest, EmployeeResponseTime } from '../employee/employee.model';
import { EmployeeService } from '../employee/employee.service';
import { JobApproverChangeRequest } from './change-approver/change-approver.model';
import { Job, JobParam, JobRequest, JobResponse, JobResponseTime } from './job.model';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);
  private companyService = inject(CompanyService);
  private employeeService = inject(EmployeeService);

  private jobData: JobResponse = [];

  getJobItem = (param: JobRequest) => {
    const {
      jobId,
      companyId,
    } = param;

    if (!companyId || !jobId) {
      return this.pageService.errorHelper('getJobItem: missing the required params.');
    }

    const jobListUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/${jobId}`;
    return this.http.get<Job>(jobListUrl);
  }

  getJobList = (param: JobRequest): Observable<JobResponseTime> => {
    const {
      companyId,
      approverId = 0,
    } = param;

    if (!companyId) {
      return this.pageService.errorHelper('getJobList: missing the required params.');
    }

    let jobListUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary`;
    if (approverId > 0) {
      jobListUrl += `?approverId=${approverId}`;
    } else {
      const existingValue: JobResponseTime = get(this.jobData, `[${companyId}]`);
      const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'job');
      if (!reloadStatus && !isEmpty(existingValue)) {
        return of(existingValue);
      }
    }

    return this.http.get<Job[]>(jobListUrl)
      .pipe(
        map((response: Job[]) => {
          const revisedResponse: JobResponseTime = {
            requestAsOf: new Date(),
            jobs: response
          };
          if (!approverId) {
            this.jobData = {
              ...this.jobData,
              [companyId]: revisedResponse
            };
            this.updateReloadStatus({ job: false });
          }
          return revisedResponse;
        })
      );
  }

  postJobInformation = (postData: Job) => {
    const {
      companyId,
    } = postData;

    if (!companyId) {
      return this.pageService.errorHelper('postJobInformation: missing the required params.');
    }

    const jobAddUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary`;
    return this.http.post<Job>(jobAddUrl, postData);
  }

  editJobInformation = (postData: Job) => {
    const {
      companyId,
      jobId,
    } = postData;

    if (!companyId || !jobId) {
      return this.pageService.errorHelper('editJobInformation: missing the required params.');
    }

    const jobEditUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/${jobId}`;
    return this.http.post<Job>(jobEditUrl, postData);
  }

  deleteJobInformation = (param: JobParam) => {
    const {
      companyId,
      id,
    } = param;

    if (!companyId || !id) {
      return this.pageService.errorHelper('deleteJobInformation: missing the required params.');
    }

    const jobDeleteUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/${id}`;
    return this.http.delete<Job>(jobDeleteUrl);
  }

  getJobListForApprover(param: JobRequest): Observable<JobSummaryByApprover[]> {
    return this.getJobList(param)
      .pipe(
        reduce((acc: JobSummaryByApprover[], jobResponse: JobResponseTime) => {
          jobResponse.jobs.forEach((job: Job) => {
            job.jobLevels.forEach((jobLevel: JobLevel) => {
              jobLevel.jobEmployeesView.forEach((jobEmployee: JobEmployeeView) => {
                if (jobEmployee.employeeId === param.approverId) {
                  const jobSummaryByApprover: JobSummaryByApprover = {
                    jobCode: job.jobCode as string,
                    jobName: job.jobName as string,
                    jobLevelCategory: jobLevel.jobLevelCategory,
                    jobLevelId: jobLevel.jobLevelId as number,
                    id: jobEmployee.id,
                    pendingRequest: jobEmployee.pendingRequest as number,
                  }
                  acc.push(jobSummaryByApprover);
                }
              });
            });
          });
          return acc;
        }, [])
      );
  }

  getJobApprovers(companyId: number): Observable<JobEmployeeView[]> {
    let jobApproverListUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/jobapprovers`;

    // TODO: Need to analyze why it's returning {}
    return this.http.get<JobEmployeeView[]>(jobApproverListUrl);
  }

  editJobApprover(postData: JobApproverChangeRequest) {
    let jobApproverChangeUrl = API_SERVICE_URL + `/company/${postData?.companyId}/jobsummary/jobapprover/change`;
    return this.http.post<JobApproverChangeRequest>(jobApproverChangeUrl, postData);
  }

  getCompanyList = (): Observable<CompanyResponseTime> => this.companyService.getCompanyInformation();

  getCompanyDetail = (companyId: number): Observable<CompanyItem> => this.companyService.getCompanyDetail(companyId);

  getEmployeeList = (param: EmployeeRequest): Observable<EmployeeResponseTime> => this.employeeService.getEmployeeList(param);

  gLog = (param: GLogger) => this.pageService.gLog(param);

  updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param);

  resetCache() {
    this.jobData = [];
  }
}
