import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty, set, unset } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { CompanyItem, CompanyResponseTime } from '../company/company.model';
import { CompanyService } from '../company/company.service';
import { DepartmentRequest, DepartmentResponseTime } from '../department/department.model';
import { DepartmentService } from '../department/department.service';
import { Employee, EmployeeRequest, EmployeeResponse, EmployeeResponseTime } from './employee.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);
  private companyService = inject(CompanyService);
  private departmentService = inject(DepartmentService);

  private employeeData: EmployeeResponse = {};

  getEmployeeList = (param: EmployeeRequest) => {
    const {
      companyId,
    } = param;

    if (!companyId) {
      return this.pageService.errorHelper('getEmployeeList: missing the required params.');
    }

    const existingValue = get(this.employeeData, `[${companyId}]`, {} as EmployeeResponseTime);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'employee', false);
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const employeeUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation`;
    return this.http.get<Employee[]>(employeeUrl)
      .pipe(
        map((response: Employee[]) => {
          const revisedResponse: EmployeeResponseTime = {
            requestAsOf: new Date(),
            employees: response
          };
          set(this.employeeData, `[${companyId}]`, revisedResponse);
          this.updateReloadStatus({ employee: false });
          return revisedResponse;
        })
      );
  }


  getEmployeeInformation = (param: EmployeeRequest) => {
    const { companyId, id } = param;

    if (!companyId || !id) {
      return this.pageService.errorHelper('getEmployeeInformation: missing the required params.');
    }

    const existingValue: EmployeeResponseTime = get(this.employeeData, `[${companyId}]`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'employee', false);
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue.employees?.find((employee: Employee) => employee.id === id) as Employee);
    }

    const employeeUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation/${id}`;

    return this.http.get<Employee>(employeeUrl);
  }

  postEmployeeInformation = (postData: Employee) => {
    const { companyId } = postData;

    if (!companyId) {
      return this.pageService.errorHelper('postEmployeeInformation: missing the required params.');
    }

    unset(postData, 'id');
    const employeeAddUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation`;
    return this.http.post<Employee>(employeeAddUrl, postData);
  }

  editEmployeePassword = (postData: Employee) => {
    const {
      id,
      companyId,
    } = postData;

    if (!companyId || !id) {
      return this.pageService.errorHelper('editEmployeeInformation: missing the required params.');
    }

    const employeeResetPasswordUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation/${id}/reset`;
    return this.http.post(employeeResetPasswordUrl, postData);
  }

  editEmployeeInformation = (postData: Employee) => {
    const {
      id,
      companyId,
    } = postData;

    if (!companyId || !id) {
      return this.pageService.errorHelper('editEmployeeInformation: missing the required params.');
    }

    const employeeEditUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation/${id}`;
    return this.http.post<Employee>(employeeEditUrl, postData);
  }

  deleteEmployeeInformation = (param: EmployeeRequest) => {
    const {
      companyId,
      id,
    } = param;

    if (!companyId || !id) {
      return this.pageService.errorHelper('deleteEmployeeInformation: missing the required params.');
    }

    const employeeDeleteUrl = API_SERVICE_URL + `/company/${companyId}/employeeinformation/${id}`;
    return this.http.delete<Employee>(employeeDeleteUrl);
  }

  getCompanyList = (): Observable<CompanyResponseTime> => this.companyService.getCompanyInformation();

  getCompanyDetail = (companyId: number): Observable<CompanyItem> => this.companyService.getCompanyDetail(companyId);

  getDepartmentList = (param: DepartmentRequest): Observable<DepartmentResponseTime> => this.departmentService.getDepartmentInformationList(param);

  updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param);

  resetCache() {
    this.employeeData = {};
  }
}
